// EN-US
export const localeEn = {
  lang: 'en',
  data: {
    ERROR_MSG: {
      INCORRECTOTP: 'Incorrect OTP Code. Please try again.',
      PASSWORD: 'Incorrect password, please try again.',
      OLDPASSWORD: 'You’ve enter previous password. Please create a new one.',
      UNREGISTEREDEMAIL: 'Email does not exist. Please sign up instead',
      UNREGISTEREDPHONE: 'Phone number does not exist. Please sign up instead',
      NOTREGISTERED: 'Email or phone number does not exist. Please sign up instead',
      REQUIRED: '{{fieldName}} cannot be empty.',
      MAXLENGTH: '{{fieldName}} must be no longer than {{requiredLength}} characters.',
      CONFIRMPASSWORD: 'Password does not match.',
      ALREADYREGISTEREDEMAIL: 'Already in use. Please enter another email or sign in instead.',
      ALREADYREGISTEREDPHONE: 'Already in use. Please enter another phone number or sign in instead.',
      EMAIL: 'Please input a valid email format.',
      PHONE: 'Please input a valid phone number format.',
      EMAILMUSTBEDIFFERENT: 'You can’t use current email as your new email.',
      CHANGEEMAILEXISTS: 'Email already in use. Please enter a different one',
      CONTENTUNAVAIL: 'The following content is not available on this app.',
    },
    TEXT: {
      WELCOME_TITLE: 'Welcome to Cinepoint',
      WELCOME_BANNER: `We measure the heartbeat of Indonesian Cinema.`
    },
    FULL_MONTH: {
      '0': 'January',
      '1': 'February',
      '2': 'March',
      '3': 'April',
      '4': 'May',
      '5': 'June',
      '6': 'July',
      '7': 'August',
      '8': 'September',
      '9': 'October',
      '10': 'November',
      '11': 'December'
    },
    SIGN_IN: {
      TITLE: 'Sign In to Account',
      DONT_HAVE_ACC:'Or don’t have an account?'
    },
    SIGN_UP: {
      PLACEHOLDER_FIRSTNAME: 'Enter your first name',
      PLACEHOLDER_LASTNAME: 'Enter your last name',
      PLACEHOLDER_EMAIL: 'mail@email.com',
      PLACEHOLDER_PHONE: '812324567890',
      PLACEHOLDER_PASSWORD: 'Enter your password',
      PLACEHOLDER_CONFIRM_PASSWORD: 'Re-type your password',
      TITLE: 'Sign Up to Cinepoint',
      ALREADY_HAVE_ACC: 'Or already have an account?',
      MUST_BE_8: 'Must be at least 8 characters',
      ONE_NO: 'One number',
      ONE_UPPER: 'One uppercase letter',
      ONE_SPECIAL: 'One special characters !@#$%^&*'
    },
    ACCOUNT_SETUP: {
      TITLE: 'You’re Almost There!',
      PROMPT: 'Please enter your email and password to continue',
    },
    SELECT_PLAN: {
      VOUCHER_NOTEXIST: 'Voucher code is invalid. Try using another code.',
      VOUCHER_EXPIRED: 'Voucher code is already expired. Try using another code.',
      VOUCHER_RUNOUT: 'Voucher quota has ran out. Try using another code.',
      VOUCHER_USED: 'You have used this voucher before. Try using another code.',
      VOUCHER_CLAIMED_FREEPLAN: 'Voucher code is valid. You’re subscribed for free until {{endDate}}.',
      VOUCHER_CLAIMED_ADDITIONAL: 'Voucher code is valid. You received additional {{duration}} day{{engplural}} of free subscription.',
      TITLE: 'Unlock all features',
      DESC: 'Get instant access to all features Cinepoint has to offer!',
      VOUCHER_PROMPT: 'Have a voucher code?',
      VOUCHER_PLACEHOLDER: 'Enter voucher code',
      BUTTON_CHECKOUT: 'Checkout for {{price}},-/{{frequency}}',
      BUTTON_CHECKOUT_FREE: 'Checkout for Free',
      ALL_BASIC: {
        TITLE: 'Access to All Basic Features',
        DESC: 'Unlock full access to all essential tools. Enjoy seamless browsing easy access to movie information without any limitations.'
      },
      REPORTING_ANALYTICS: {
        TITLE: 'Basic Reporting and Analytics',
        DESC: 'Stay informed about box office trends and movie performances with basic reporting and analytics.'
      },
      COMPARISON: {
        TITLE: 'Movie Comparison',
        DESC: 'Compare admission rates, gross earnings, showtimes, and ratings of up to 3 movies side by side of your choice.'
      }
    },
    SELECT_PAYMENT: {
      CODE_EXPIRED: 'Promo code is already expired. Try using another code.',
      CODE_NOTEXIST: 'Promo code is invalid. Try using another code.',
      CODE_RUN_OUT: 'Promo quota has ran out. Try using another code.',
      CODE_USED: 'You have used this code before. Please try using another code.',
      CODE_VALID: 'Promo code is valid',
      TITLE: 'Select payment method',
      DESC: 'Choose your preferred payment option',
      PROMO_PROMPT: 'Have a promo code?',
      PROMO_CODE_PLACEHOLDER: 'Enter promo code',
      BASIC_FEATS: 'Access to all basic features',
      UPCOMING_MOVIES: 'Get the latest info on upcoming movies',
      BASIC_REPORTING: 'Basic reporting and analytics',
      BASIC_SUPPORT: 'Basic chat and email support',
      MOVIE_COMPARISON: 'Movie comparison',
      ANOTHER_FEAT: 'Another feature',
      CHARGE_DESCRIPTION: 'You’ll be charged Rp{{price}},- /{{frequency}} until you decided to cancel your subscription.',
      WHAT_YOU_GET: 'What you’ll get in this plan :',

    },
    VERIF_EXPIRED: {
      TITLE: 'Verification link has expired',
      DESC: 'Looks like the verification link has expired. But rest assured, we can send the link again.'
    },
    ACC_VERIFIED: {
      TITLE: 'Your account is verified!',
      DESC: 'Your account has been successfully verified. Click “Next” to continue.'
    },
    NEW_EMAIL_VERIFIED: {
      TITLE: 'Your new email is verified!',
      DESC: 'Your new email has been successfully verified. Please “Sign In” to continue.'
    },
    NOT_LOGGED_IN: {
      TITLE: 'You have not logged in!',
      DESC: 'Please click the Sign In button on the Home page and enter your username and password.'
    },
    SESSION_EXPIRED: {
      TITLE: 'Your session has expired!',
      DESC: 'You can try to Sign In to your account again, or go to our Homepage.'
    },
    VERIFY_OTP: {

      TITLE: 'Verify Phone Number',
      HAVE_SENT: 'We have sent OTP code to',
      PROMPT: 'Please enter the code below.',
      DID_NOT_RECEIVE: 'Didn’t receive the code?'
    },
    VERIFY_ACCOUNT: {
      TITLE: 'Verify your account',
      VERIFY_PROMPT: 'You will need to verify your email to complete the registration',
      EMAIL_SENT_START: 'An email has been sent to ',
      EMAIL_SENT_END: ' with a link to verify your account.',
      EMAIL_SENT_VERIFY: 'Please verify your account first before signing in.',
      RESEND_VERIFICATION_LINK: 'Resend verification link'
    },
    ACTIVATE_ACCOUNT: {
      TITLE: 'Activate Your Account',
      VERIFY_PROMPT: 'You will need to verify your email to complete the setup',
      EMAIL_SENT_START: 'An email has been sent to ',
      EMAIL_SENT_END: ' with a link to activate your account.',
      EMAIL_SENT_VERIFY: 'Please activate your account first before signing in.',
      RESEND_VERIFICATION_LINK: 'Resend activation link'
    },
    PAYMENT_SUCCESS: {
      TITLE: 'Payment Success!',
      DESC: 'Yay, you’re subscribed! Now you can enjoy our awesome features!'
    },
    PAYMENT_UNFINISH: {
      TITLE: 'Payment Incomplete!',
      DESC: 'You have not completed the payment. Please select a plan and continue to payment.'
    },
    PAYMENT_ERROR: {
      TITLE: 'Error Processing Payment!',
      DESC: 'There was an error while processing payment. Please check with your payment service provider if the transaction was processed or not. You may need to repeat selecting plan and payment.'
    },
    EMAIL_SENT: {
      TITLE: 'Email Sent!',
    },
    PASSWORD_FORM: {
      GREETING: 'Hi, {{name}}!',
      PROMPT: 'Please enter your password to continue the Sign In proccess.',
      REMEMBER_PASSWORD: 'Remember for 30 days'
    },
    FORGOT_PASSWORD: {
      TITLE: 'Forgot Password',
      PROMPT: 'Please enter your registered email address to change your password.',
      EMAIL_SENT_END: ' with a link to change your password.',
    },
    CREATE_PASSWORD: {
      TITLE: 'Create New Password',
      DESC: 'Please enter your new password.'
    },
    PASSWORD_CHANGED: {
      TITLE: 'Password Changed!',
      DESC: 'Your password has been changed successfully. Now you can Sign In using your new password.'
    },
    FOOTER: {
      SHORT_DESC: 'We measure the heartbeat of Indonesian Cinema.',
      GET_APP: 'Get the app now!',
      COPYRIGHT: '© 2024 Cinepoint. All rights reserved.'
    },
    SIGNED_OUT: {
      TITLE: 'You have been signed out!',
      DESC: 'Please wait while you are being redirected to the home page.'
    },
    TOAST: {
      OTP_RESENT: {
        TITLE: 'OTP code resent!',
        DESC: 'Please check your phone’s inbox to get OTP code.'
      },
      OTP_REACHED_LIMIT: {
        TITLE: 'OTP Limit Reached!',
        DESC: 'Please try again 24 hours later'
      },
      OTP_SEND_ERROR: {
        TITLE: 'Error sending OTP!',
        DESC: 'There is an unexpected error while requesting a new OTP code.'
      },
      ERROR_GET_SUBS: {
        TITLE: 'Unable to retrieve subscription!',
        DESC: 'Please try refreshing or signing in again, or contact our customer support if problem persists.',
      },
      ERROR_LOGIN: {
        TITLE: 'Unable to sign in!',
        DESC: 'An error occurred while trying to sign you in. Please try again or contact our administrators.',
      },
      VERIF_LINK_RESENT: {
        TITLE: 'Verification link resent!',
        DESC: 'Please check your email (inbox or spam folder) and click verification link to verify your email.',
      },
      VERIF_LINK_RESEND_ERROR: {
        TITLE: 'Request for resend fails!',
        DESC: 'The system does not recognize the email address you entered.'
      },
      SUBSCRIPTION_CANCELLED: {
        TITLE: 'You’re no longer subscribed to Cinepoint.',
        DESC: 'Start a subscription to access your account and fully enjoy our features.'
      },
      SUBSCRIPTION_CANCEL_ERROR: {
        TITLE: 'Sorry, we are unable to process your request to cancel.',
        DESC: 'The server might be experiencing some issues or your subscription was already cancelled. If problem persists, please contact our administrators.'
      },
      SUBSCRIPTION_ENDED: {
        TITLE: 'Your Subscription has ended.',
        DESC: 'Please renew your subscription to continue use your account.'
      },
      SUBSCRIPTION_NOTEXIST: {
        TITLE: 'You are not currently subscribed to Cinepoint.',
        DESC: 'Please select a subscription plan before continuing to use your account.'
      },
      PERSONAL_INFO_ERROR: {
        TITLE: 'Saving Personal Info Failed!',
        DESC: 'An unexpected error occurred while saving your new profile information. Please check your input'
      },
      NO_CHANGE_PLAN: {
        TITLE: 'Annother Plan will be available soon',
      }
    },
    PERSONAL_INFORMATION: {
      MIN_AGE_ERROR: 'You must be at least 17 years old.',
      DESC: 'Manage your profile information to control, protect and secure your account'
    },
    PASSWORD_N_SECURITY: {
      DESC: 'Manage your profile information to control, protect and secure your account'
    },
    DIALOG_MESSAGE: {
      SAVE_CHANGES: 'Are you sure to save these changes?'
    },
    CONFIRM_SAVE: {
      TITLE: 'Save Changes',
      PROMPT: 'Are you sure to save these changes?',
    },
    SUBSCRIPTION_DETAIL: {
      TITLE: 'Subscription Detail',
      DESC: 'Your current subscription details'
    },
    SUBSCRIPTION_CANCEL:{
      TITLE: 'Cancel Subscription',
      SUBTITLE: 'We’re very sad to see you go, please let us know why you’re decided to stop your subscription',
      PROMPT: 'Are you sure you want to cancel your subscription? Once you’re unsubscribed, you will lose these features :',
      FEATS: {
        WRITE_REVIEW: 'No longer able to write review',
        COMPARE_MOVIES: 'No longer able to compare movies'
      },
      REASON: {
        TECH_ISSUES: 'Technical Issues',
        TOO_EXPENSIVE: 'Too expensive',
        SWITCH_PRODUCT: 'Switching to another product',
        NO_MORE_NEED: 'No longer need this service',
        RARE_USE: 'I rarely use the app',
        OTHERS: 'Others',
      }
    },
    SIGN_OUT: {
      PROMPT: 'Are you sure you want to logout from your account?'
    },
    CHANGE_PASSWORD: {
      DESC: 'Update your security credentials regularly',
    },
    CHANGE_PHONE: {
      TOOLTIP: '<span>You can contact admin via email <a class="text-primary-500 underline" href="mailto:help@cinepoint.com">help@cinepoint.com</a> to request change phone number.</span>'
    },
    CANCEL_CHANGE_EMAIL: {
      PROMPT: 'Are you sure you want to cancel this process?'
    },
    MOVIE_DIRECTORY: {
      DISPLAYING_TOTAL_RESULTS: 'Displaying {{total}} search results',
      COMPARE_WITH_OTHER_LINK: 'Compare with other movies. Try Now'
    },
    ADD_MOVIE_DIALOG: {
      PROMPT: 'Add movie to start movie comparison',
      SEARCH_PLACEHOLDER: 'Search for movie title or casts name',
      NO_RESULT: 'No result. Please try another keyword.'
    },

    ABOUT_US: {
      BANNER_DESC: 'Learn more about the company and team behind it.',
      PAGE_DESC: '',
      CONTENT: `
        Cinepoint is the most trusted recommendation resources for quality entertainment. As a leading online aggregator of Box Office Movies, we provide fans to give ratings for movies.  If you’re an entertainment fan looking for a recommendation, or to share ratings, you’ve come to the right place.
      `
    },

    FAQ: {
      BANNER_DESC: '',
      PAGE_DESC: ''
    },

    TERMS_CONDITION: {
      BANNER_DESC: '',
      PAGE_DESC: '',
      CONTENT: `
        Yo Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam.

        Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat.

        Odio felis sagittis, morbi feugiat tortor vitae feugiat fusce aliquet. Nam elementum urna nisi aliquet erat dolor enim. Ornare id morbi eget ipsum. Sapien, dictum molestie sem tempor.

        Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat.

        Odio felis sagittis, morbi feugiat tortor vitae feugiat fusce aliquet. Nam elementum urna nisi aliquet erat dolor enim. Ornare id morbi eget ipsum. Sapien, dictum molestie sem tempor.
      `
    },

    PRIVACY_POLICY: {
      BANNER_DESC: '',
      PAGE_DESC: '',
      CONTENT: `
        Yo Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam.

        Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat.

        Odio felis sagittis, morbi feugiat tortor vitae feugiat fusce aliquet. Nam elementum urna nisi aliquet erat dolor enim. Ornare id morbi eget ipsum. Sapien, dictum molestie sem tempor.

        Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat.

        Odio felis sagittis, morbi feugiat tortor vitae feugiat fusce aliquet. Nam elementum urna nisi aliquet erat dolor enim. Ornare id morbi eget ipsum. Sapien, dictum molestie sem tempor.
      `
    },

    CONTACT_US: {
      BANNER_DESC: 'We’d love to hear from you. Our friendly team is always here to chat.',
      PAGE_DESC: 'We’d love to hear from you. Our friendly team is always here to chat.',
      GET_IN_TOUCH: 'Get In Touch with Us!',
      EMAIL_DESC: 'Our friendly team is here to help.',
      ADDRESS_DESC: 'Come say hello at our office HQ.',
      PHONE_DESC: 'Mon-Fri from 8am to 5pm.'
    },

    DAILY_SHOWTIME: {
      CHART_TITLE: '“Top 8” Showtime Comparison',
    },
    PAGE_NOT_FOUND: {
      TITLE: 'Page Not Found',
      DESC: 'Sorry, the page you are looking for doesn\'t exist or has been moved.',
    },
    UNDER_MAINTENANCE: {
      TITLE: 'Website Under Maintenance',
      DESC: 'Sorry, our website is currently under maintenance, but we’re almost done. \nYou can try again at some time.',
    },
    CHANGE_EMAIL: {
      SUBTITLE: 'Update your contact information',
    },
    CHANGE_PHONE_NUMBER: {
      SUBTITLE: 'Update your contact information',
    },
    SUBSCRIPTION: {
      SUBTITLE: 'Manage your membership and benefits'
    },
    CHART: {
      NO_DATA: 'There is no data for this movie.',
    },
    LOCKED_MESSAGE: {
      SUBSCRIBED_ONLY: 'This is for subscription users.',
      LOGIN_TO_SEE: 'Login to your account to see this chart.',
    },
    CHANGE_EMAIL_SAVED: {
      TITLE: 'Request Submitted!',
      DESC: 'Your email has been updated, but you need to verify. Please check your email (inbox or spam folder) and click verification link to verify your email. <b>Please don’t close this page if you haven’t verify your new email address.</b>'
    },
    CHANGE_PASSWORD_SAVED: {
      TITLE: 'Changes Saved!',
      DESC: 'Your password has been updated. Please use your new password to sign in.'
    },
    PERSONAL_INFO_SAVED: {
      TITLE: 'Changes Saved!',
      DESC: 'Your personal information has been updated.'
    },
    TOP_BOX_OFFICE: {
      DISCLAIMER: 'Data displayed are a combination of published data and Cinepoint’s proprietary tracking estimates.',
      TITLE: {
        DAILY: 'Daily Top Box Office',
        WEEKLY: 'Weekly Top Box Office',
        MONTHLY: 'Monthly Top Box Office',
        YEARLY: 'Yearly Top Box Office',
      }
    },
    DEMOGRAPHIC: {
      DESC: 'Based on user’s rating data',
    },
    BLOG: {
      MORE_LIKE_THIS: 'More Articles',
      SUBHEADER: 'Learn about the latest news about your favorite movies and actors here.',
      DELETE_COMMENT: 'Delete Comment',
      DELETE_COMMENT_DESC: 'Are you sure want to delete this comment?',
      WARNING_COMMENT: 'This comment section is monitored by admin. Any comments containing negativity, SARA, or pornographic content will be deleted.'
    },
    COMMON: {
      /* Other */
      CATEGORY: 'Category',
      SUBPERIOD: 'Subperiod',
      COMMENTS: 'Comments',
      BLOG: 'Blog',
      BLOG_ARTICLES: 'Blog & Articles',
      BY: 'By',
      LOGIN_NOW: 'Login now',
      ERROR_PAGE: 'Error Page',
      ERROR_404: '404 Error',
      PREVIOUS_DAY: 'Previous Day',
      NEXT_DAY: 'Next Day',
      PASSWORD_SECURITY: 'Password & Security',
      DETAIL: 'Detail',
      PAYMENT_HISTORY: 'Payment History',
      PERSONAL_INFO: 'Personal Info',
      PASSWORD_N_SECURITY: 'Password & Security',
      MY_PLAN: 'My Plan',
      VIEW_DETAILS:'View Details',
      NO_RECORDS_FOUND: 'No records found',

      CHAT_TO_US: 'Chat to us',
      OFFICE: 'Office',
      PHONE: 'Phone',
      ABOUT_CINEPOINT: 'About Cinepoint',
      ABOUT_US: 'About Us',
      FAQ_LONG: 'Frequently Asked Questions',
      FAQ: 'FAQ',
      CONTACT_US: 'Contact Us',
      ADD_MOVIE: 'Add movie',
      MOVIE_COMPARISON: 'Movie Comparison',
      SIMILAR_MOVIES: 'Similar Movies',
      PLAYING_AT: 'Playing at',
      GO_TO_WEBSITE: 'Go to website',
      OTHERS: 'Others',
      TOTAL_7_DAYS: '7-Day Total',
      TOTAL_14_DAYS: '14-Day Total',
      COMPARE_WITH_OTHER_MOVIES: 'Compare with other movies',
      ADMISSION: 'Admission',
      GROSS: 'Gross',
      DEMOGRAPHIC: 'Demographic',
      THIS_WEEKS_ADMISSION: 'This Week\'s Admission',
      CAST_N_CREW: 'Cast & Crew',
      RELEASED: 'Released',
      INFORMATION: 'Information',
      PRODUCTION_STATUS: 'Production Status',
      RUNNING_TIME: 'Running Time',
      COUNTRY: 'Country',
      LANGUAGE: 'Language',
      COMPARE: 'Compare',
      PLAY_TRAILER: 'Play Trailer',
      RELEASE_DATE: 'Release Date',
      RESET_FILTER: 'Reset Filter',
      POPULAR_MOVIES: 'Popular Movies',
      GENRE: 'Genre',
      RATING: 'Rating',
      SORT: 'Sort',
      ALL: 'All',
      OTHER: 'Other',
      CHANGE_EMAIL: 'Change Email',
      CHANGE_PHONE_NUMBER: 'Change Phone Number',
      MOVIE_DIRECTORY: 'Movie Directory',
      CANCEL_CHANGE_EMAIL: 'Cancel Change Email',
      CANCEL_CHANGE_PASSWORD: 'Cancel Change Password',
      NO: 'No',
      YES: 'Yes',
      VERIFIED: 'Verified',
      UNVERIFIED: 'Unverified',
      CHANGE_PASSWORD: 'Change Password',
      CONFIRM_NEW_PASSWORD: 'Confirm New Password',
      NEW_PASSWORD: 'New Password',
      SAVE_PASSWORD: 'Save Password',
      SELECT_REASON: 'Select reason',
      EMAIL_SENT_START: 'An email has been sent to ',
      EMAIL_SENT_END: ' with a link to verify your account.',
      EMAIL_SENT_VERIFY: 'Please verify your account first before signing in.',
      PLACEHOLDER_PASSWORD: 'Enter password',
      FORGOT_PASSWORD: 'Forgot password',
      MY_PROFILE: 'My Profile',
      LOGOUT: 'Sign out',
      ACCOUNT_SETUP: 'Account Setup',
      RESEND_CODE: 'Resend Code',
      BACK: 'Back',
      SUBMIT: 'Submit',
      OR_PHONE_NO: 'Or Phone Number',
      PLACEHOLDER_EMAIL: 'Enter your email address',
      PLACEHOLDER_PHONE: 'Enter your phone number',
      NEED_HELP: 'Need help?',
      HELP_CENTER: 'Help Center',
      TERMS_CONDITION: 'Terms & Condition',
      PRIVACY_POLICY: 'Privacy Policy',
      BROWSE_MOVIE: 'Browse movie',
      SUMMARY: 'Summary',
      SUBTOTAL: 'Subtotal',
      TAX: 'Tax',
      DISCOUNT: 'Discount',
      TOTAL: 'Total',
      PAY_NOW: 'Pay Now',
      SUBSCRIPTION: 'Subscription',
      CHANGE_PLAN: 'Change Plan',
      APPLY: 'Apply',
      MONTH: 'Month',
      YEAR: 'Year',
      POPULAR: 'Popular',
      MONTHLY_PLAN: 'Monthly Plan',
      YEARLY_PLAN: 'Yearly Plan',
      RECOMMENDED: 'Recommended',
      NEXT: 'Next',
      RESEND_VERIFICATION_LINK: 'Resend verification link',
      CONTINUE: 'Continue',
      FIRST_NAME: 'First Name',
      LAST_NAME: 'Last Name',
      EMAIL: 'Email',
      PHONE_NO: 'Phone number',
      PASSWORD: 'Password',
      CONFIRM_PASSWORD: 'Confirm Password',
      SIGN_IN: 'Sign In',
      SIGN_UP: 'Sign Up',
      SIGN_OUT: 'Sign out',
      SELECT_PLAN: 'Select Plan',
      PAYMENT: 'Payment',
      ALL_COUNTRIES: 'All Countries',
      LOCAL: 'Local',
      INTERNATIONAL: 'International',
      PERIOD: 'Period',
      ROWS_PER_PAGE: 'Rows per page',
      RANK: 'Rank',
      TITLE: 'Title',
      WEEKLY_ADM: 'Weekly Adm.',
      CHANGE: 'Change',
      TOTAL_ADM: 'Total Admission',
      SHOWTIMES: 'Showtimes',
      SCORE: 'Score',
      WEEKLY: 'Weekly',
      WEEKLY_BY_RELEASE_DATE: 'Weekly By Release Date',
      DAILY: 'Daily',
      DAILY_ADM: 'Daily Adm.',
      MONTHLY_ADM: 'Monthly Adm.',
      YEARLY_ADM: 'Yearly Adm.',
      MONTHLY: 'Monthly',
      YEARLY: 'Yearly',
      TOP_BOX_OFFICE: 'Top Box Office',
      SEPTEMBER: 'September',
      OCTOBER: 'October',
      NOVEMBER: 'November',
      UPCOMING_MOVIES: 'Upcoming Movies',
      SEE_ALL: 'See all movies',
      LATEST_MOVIES: 'Latest Movies',
      HOME: 'Home',
      BACK_TO_HOME: 'Back to Home Page',
      DAILY_SHOWTIME: 'Daily Showtime',
      MOVIE_DIR: 'Movie Directory',
      MOVIE_COMP: 'Movie Comparison',
      MOVIES: 'Movies',
      CASTS: 'Casts',
      CAST: 'Cast',
      PRODUCER: 'Producer',
      WRITER: 'Writer',
      DIRECTOR: 'Director',
      CHANGE_IMAGE: 'Change Image',
      ENTER: 'Enter',
      ENTER_YOUR: 'Enter Your',
      DATE_OF_BIRTH: 'Date Of Birth',
      GENDER: 'Gender',
      MALE: 'Male',
      FEMALE: 'Female',
      SAVE_CHANGES: 'Save Changes',
      CANCEL_SUBSCRIPTION: 'Cancel Subscription',
      STAY_SUBSCRIBED: 'Stay Subscribed',
      CANCELATION_REASON: 'Cancellation Reason',
      FEEDBACK: 'Feedback',
      NOTE: 'Note',
      CANCEL_MY_SUBSCRIPTION: 'Cancel My Subscription',
      DATE: 'Date',
      DESCRIPTION: 'Description',
      PAYMENT_METHOD: 'Payment Method',
      CURRENT_PASSWORD: 'Current Password',
      CURRENT_PLAN: 'Current Plan',
      CANCEL: 'Cancel',
      PERSONAL_INFORMATION: 'Personal Information',
      MOVIE_POSTER_UNAVAIL: 'Movie Poster\nNot Available',
      IMAGE_UNAVAIL: 'Image\nNot Available',
      UPLOAD_IMAGE: 'Upload Image (JPG/PNG)',
      MAX_SIZE_5MB: 'Max. size 5 MB',
      UPLOAD_IMAGE_SUCCESSFULL: 'Upload image successful !',
      IMAGE_SIZE_TOO_BIG: 'Image size is too big !',
      INVALID_FILE_FORMAT: 'Incorrect file format !',
      ERROR: 'Error',
      SAVE: 'Save',
      DATA_CHANGES: 'Data Changes',
      BILLED_MONTHLY: 'Billed monthly',
      BILLED_YEARLY: 'Billed yearly',
      NEXT_BILLING_DATE: 'Next Billing Date',
      PASSWORD_AND_SECURITY: 'Password & Security',
      TAKE_ME_HOME: 'Take Me Home',
      SEE_ALL_CAST_N_CREW: 'See all cast & crew',
      VOUCHER_CODE:'Voucher Code',
      /* Movie Genre */
      SPORT: 'Sport',
      ACTION: 'Action',
      HORROR: 'Horror',
      DRAMA: 'Drama',
      THRILLER: 'Thriller',
      'SCI-FI': 'Science Fiction',
      ROMANCE: 'Romance',
      COMEDY: 'Comedy',
      CRIME: 'Crime',
      WESTERN: 'Western',
      ANIMATION: 'Animation',
      DOCUMENTARY: 'Documentary',
      FANTASY: 'Fantasy',
      ADVENTURE: 'Adventure',
      MUSICAL: 'Musical',
      HISTORICAL: 'Historical',
      NARRATIVE: 'Narrative',
      NOIR: 'Noir',
      EXPERIMENTAL: 'Experimental',
      ROMANTIC_COMEDY: 'Romantic Comedy',
      MUSIC: 'Music',
      MYSTERY: 'Mystery',
      TELEVISION: 'Television',
      HISTORICAL_FICTION: 'Historical Fiction',
      FICTION: 'Fiction',
      SPAGHETTI_WESTERN: 'Spaghetti Western',
      ART: 'Art',
      DISASTER: 'Disaster',
      WAR: 'War',
      SHORT: 'Short',
      EXPLOITATION: 'Exploitation',
      SATIRE: 'Satire',
      SLASHER: 'Slasher',
      BIOGRAPHY: 'Biography',
      SORT_N_FILTER_MOVIE: 'Sort & Filter Movie',
      FILTER: 'Filter',
      UNTIL: 'Until',
      AUTOMATICALLY_EXTENDED: 'Automatically Extended',
      LATEST: 'Latest',
      ENTER_YOUR_COMMENT_HERE: 'Enter your comment here',
      POST_COMMENT: 'Post Comment',

      /* Movie Rating */
      G: 'G',
      PG: 'PG',
      'PG-13': 'PG-13',
      R: 'R',
      'NC-17': 'NC-17',
      NOT_RATED: 'Not Rated',
      UNRATED: 'Unrated',
    },

  },
};
